.error {
    display: flex;
    justify-content: center;
    color: rgb(156, 16, 16);
}

.noData {
    display: flex;
    justify-content: center;
}

.searchOption {
    display: flex;
    justify-content: space-between;
}

.searchOptionLeft {
    display: flex;
    align-items: center;
}

.options {
    font: normal normal normal 18px/22px Helvetica;
    color: #000000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C1C6D3;
    border-radius: 24px !important;
}

.sortBy {
    color: #625df5;
}

.tableHeader {
    border-top: 1px solid #C1C6D3;
    border-bottom: 1px solid #C1C6D3;
}