.options {
    font: normal normal normal 18px/22px Helvetica;
    color: #000000 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C1C6D3 !important;
    border-radius: 24px !important;
}

.optionsWithoutBorder {
    font: normal normal normal 18px/22px Helvetica;
    color: #000000 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0px solid #C1C6D3 !important;
    border-radius: 24px !important;
    padding-left: 0px !important;
}

.text {
    width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-transform: none;
}