.container {
    display: flex;
    height: 100%;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
}

.main {
    margin: 200px;
}

@media only screen and (max-width: 1400px) {
    .main {
        margin: 50px;
    }
}

.login {
    padding-left: 50px;
    padding-right: 50px;
}

.image {
    padding-right: 40px;
}

@media only screen and (max-width: 899px) {
    .image {
        display: none;
    }

    .main {
        margin: 0px;
    }

    .login {
        padding: 0px 100px;
    }
}

@media only screen and (max-width: 598px) {
    .image {
        display: none;
    }
}